function NavBar() {
    return (
        <nav>
            <div className="logo">
            <img src="" alt=""/><h1>Abdul Sultan Inc.</h1>
            </div>
            <ul className="nav-items">
                <li><a href="http://">Home</a></li>
                <li><a href="http://">Projects</a></li>
                <li><a href="http://">About</a></li>
            </ul>
        </nav>
    );
}

export default NavBar;
